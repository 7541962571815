import * as React from "react"
import Layout from "../components/layout"

export default function IndexPage() {
  return (
    <Layout>
      <div className="flex h-screen">
        <div className="px-4 py-8 m-auto w-full lg:w-1/2">
          <div className="text-4xl lg:text-6xl mb-6">Öppettider jul & nyår</div>
          <table class="table-auto text-xl w-full lg:w-1/2">
            <tbody>
              <tr>
                <td>23 december</td>
                <td>11:30-14:30</td>
              </tr>
              <tr>
                <td>24-26 december</td>
                <td>Stängt</td>
              </tr>
              <tr>
                <td>27-30 december</td>
                <td>17:00-22:00</td>
              </tr>
              <tr>
                <td>31 december</td>
                <td>17:00-23:00</td>
              </tr>
              <tr>
                <td>1 januari</td>
                <td>16:00-20:00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}
